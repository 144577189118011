<template>
  <div :class="b()">
    <CardFeedback :departmentId="null" :doctorId="this.docId" type="doctor" />
  </div>
</template>

<script>
import CardFeedback from "components/card-feedback/CardFeedback";
import * as names from "store/names";
import { mapGetters } from "vuex";

export default {
  name: "DoctorCardFeedback",
  components: { CardFeedback },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      docId: names.DOCTOR_ID,
    }),
  },

  methods: {},
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
</style>
